<template>
  <div
    data-testid="iconcomponent"
    class="icon-renderer ui-flex ui-justify-center ui-items-center"
    v-html="
      getSvgIcon({
        name: name,
        width: width,
        height: height,
        svgClass: computedClass,
        color: color,
      })
    "
  />
</template>

<script lang="ts" setup>
import type { SvgIconProps } from "./types";
import { computed } from "vue";
import { getSvgIcon } from "./getSvgIcon";
import { toRefs } from "vue";

const props = defineProps<SvgIconProps>();
const { name, width, height, svgClass, color } = toRefs(props);

const computedWidth = computed(() => `${width.value}px`);
const computedHeight = computed(() => `${height.value}px`);
const computedColor = computed(() => color);
const computedClass = computed(() => svgClass.value || "");
</script>

<style scoped>
.icon-renderer {
  width: v-bind(computedWidth);
  height: v-bind(computedHeight);
  color: v-bind(computedColor);
}
</style>
